<template>
    <div class="login-v3-page" id="login-v3-itees">
        <header>
            <b-container class="k-relative mb-xl-5 mb-2">
                <b-row class="align-center">
                    <b-col cols-md="3" cols="5">
                        <div class="k-logo">
                            <a href="/">
                            	<img :src="require('@/assets/images/logo/ITEES-logo.png')" alt="">
                            </a>
                        </div>
                    </b-col>
                    <b-col cols-md="9" cols="7">
                        <div class="k-nav flex-align-center">
								<div>
									
									<a :href="`${baseURL}/contact-us/`" class="k-btn-default k-btn-main">Contact Us</a>
								</div>
							</div>
                    </b-col>
                </b-row>
            </b-container>            
        </header>
        <div class="template-login-page">
            <div class="login-group d-flex align-center">
                <div class="group group-form order-md-2">
                    <h2 class="h2 size-55 k-main-color">
                        <strong>Login</strong>
                    </h2>
                    <div class="login-frm">
						<validation-observer
							ref="loginForm"
							#default="{invalid}"
						>
							<b-form @submit.prevent="login">
								<!-- email -->
								<b-form-group
									label-for="login-email"
									class="login-username"
								>
									<validation-provider
									#default="{ errors }"
									name="Email"
									vid="email"
									rules="required"
									>
									<b-form-input
										id="login-email"
										v-model="userEmail"
										:state="errors.length > 0 ? false:null"
										name="login-email"
										placeholder="Username/Email"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
								<!-- password -->
								<b-form-group
									class="login-password"
								>
									<validation-provider
									#default="{ errors }"
									name="Password"
									vid="password"
									rules="required"
									>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid': null"
									>
										<b-form-input
											id="login-password"
											v-model="password"
											:state="errors.length > 0 ?  false : null"
											class="form-control-merge"
											:type="passwordFieldType"
											name="login-password"
											placeholder="Password"
										/>
										<div class="cursor-pointer icon-password" @click="togglePasswordVisibility">
											<img :src="imgEye" alt="">
											<div class="closed-eye" v-if="showPassword"></div>
										</div>
									
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
								<b-button
									type="submit"
									variant="primary"
									block
									class="login-submit"
									:disabled="invalid"
								>
									Sign in
								</b-button>
							</b-form>
						</validation-observer>
                    </div>
                </div>
                <div class="group group-img k-bg-default" :style="{ 'background-image': `url(${require('@/assets/images/itees/Login-banner.jpg')})` }">
				    <img :src="require('@/assets/images/itees/Login-banner.jpg')" alt="" class="w-100 fade">
			    </div>

            </div>
        </div>
        <footer class="content-foot">
            <b-container>
                <div class="foot-bar-size d-flex align-items-end white-color">
                    <div class="foot-menu">
                        <ul class="list-unstyled mb-0">
                            <li><a :href="`${baseURL}/terms-and-conditions/`" >Terms and Conditions</a></li>
                            <li><a :href="`${baseURL}/privacy-policy`">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div class="text-right white-color footer-icon">
                        <ul class="list-unstyled">
                            <li><a href="https://www.facebook.com/ITEEducationServices/" target="_blank"><img :src="require('@/assets/images/itees/Facebook-ico.png')" alt=""></a></li>
                            <li><a href="https://mp.weixin.qq.com/s/7uPR9iiOJ5DriHf4RcBebQ" target="_blank"><img :src="require('@/assets/images/itees/Chat-ico.png')" alt=""></a></li>
                        </ul>
                        <span>© ITE Education Services {{ new Date().getFullYear() }}</span>	
                    </div>
                </div>	
				<div class="foot-bar-mb white-color text-center">
					<span>© ITE Education Services {{ new Date().getFullYear() }}</span>
				</div>
            </b-container>
        </footer>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            password: '',
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            // validation rulesimport store from '@/store/index'
            required,
            email,
			tabIndex: 0,
			tabIndexChild: 0,
			isShow: false,
			eyeImg: require('@/assets/images/svg/eye-ico.svg'),
			showPassword: false,
        }
    },
    computed: {
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
		imgEye() {
			return this.eyeImg;
		},
		baseURL() {
			return process.env.VUE_APP_URL_WP || '';
		},
    },
	created(){
		let customScript = document.createElement('script');
		//customScript.setAttribute('src',require('@/script/custom.js'));
		customScript.setAttribute('src','/script/custom.js');
		document.head.appendChild(customScript);
	},
    methods: {
        validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Form Submitted',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    })
                }
            })
        },
		mouseOver(idx) {
			this.tabIndex = idx;
		},
		mouseOverTabChild(idx) {
			this.tabIndexChild = idx;
		},
		openMenu(){
			this.isShow = !this.isShow;
			this.tabIndex = 0;
			this.tabIndexChild = 0;
		},
		closeMenu(){
			this.isShow = false;
			this.tabIndex = 0;
			this.tabIndexChild = 0;
		},
		login() {
			this.$refs.loginForm.validate().then(success => { console.log(success, 'success')
				if (success) {

					
					this.$store.dispatch('auth/login', {
						email: this.userEmail,
						password: this.password,
					})
					.then(response => {
						if(response.data.data.first_login != 0){
							this.$router.push(`/change-password-first-login`)
							return;
						}else{
							this.$router.replace(getHomeRouteForLoggedInUser(response.data.data.group_id))
						}
					})
					.catch((error) => {	
				        this.$bvToast.toast('The account/email does not exist or the password is incorrect.', {
				          title: `Oops...`,
				          variant: 'danger',
				          solid: true
				        })
						console.log('error: ', error);
					})
				}
			})
		},
    },
	watch: {
		passwordFieldType: function(val) {
			if(val === 'text') {
				return this.showPassword = true;
			}
			return this.showPassword = false;
		},
	}
}
</script>

<style lang="scss">
	@import '@core/scss/vue/pages/login-v3.scss';
</style>